export const fullProjection = `mutation($input: SocialMediaInput!) {
  updateSocialMedia(input: $input) {
    facebook
    instagram
    ebay
    twitter
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: SocialMediaInput!) {
  updateSocialMedia(input: $input) {
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateSocialMedia`;

const updateSocialMedia = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateSocialMedia;