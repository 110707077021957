export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllAwyesImage(query: $query) {
    nextToken
    items {
      ... on AwyesImage {
        id
        path
        thumbPath
        name
        mime
        width
        height
        size
        eTag
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllAwyesImage(query: $query) {
    nextToken
    items {
      ... on AwyesImage {
        id
        name
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllAwyesImage`;

const getAllAwyesImage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllAwyesImage;