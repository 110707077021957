export const fullProjection = `mutation($input: ReturnsPolicyInput!) {
  updateReturnsPolicy(input: $input) {
    policy
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: ReturnsPolicyInput!) {
  updateReturnsPolicy(input: $input) {
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateReturnsPolicy`;

const updateReturnsPolicy = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateReturnsPolicy;