export const fullProjection = `query($where: ProductTypePageSearchInput!) {
  searchProductTypePage(where: $where) {
    nextToken
    items {
      ... on ProductTypePage {
        title
        url
        category {
          title
          url
          name
          header
          id
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          description
          keywords
          priority
          typeName: __typename
        }
        name
        header
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        description
        keywords
        priority
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `searchProductTypePage`;

const searchProductTypePage = {
  fullProjection,
  resolverField,
};

export default searchProductTypePage;