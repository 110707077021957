
const multiplyByTenToThePower = (value, dp) => value + 'e' + dp;
const divideByTenToThePower = (value, dp) => value + 'e-' + dp;
const convertStringToFloat = (value) => parseFloat(`${value}`.replace(/[^\d.]/g, ''));
const roundNumber = (value, dp = 2) => {
  const num = isNaN(value) ? convertStringToFloat(value) : value;
  if (isNaN(num)) return value;
  return Number(divideByTenToThePower(Math.round(multiplyByTenToThePower(num, dp)), dp));
}

export const formatPrice = (rawPrice) => (rawPrice ? roundNumber(rawPrice) : 0.00).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }).replace(/\.00$/, '');

export const convertToSlug = (raw) => (raw ?? '').trim().toLowerCase().replace(/[^a-z0-9\s_/-]/gi, '-').replace(/[-_\s]+/g, '-');

export const convertToParam = (raw) => (raw ?? '').trim().toLowerCase().replace(/[^a-z0-9\s_-]/gi, '-').replace(/[-_\s]+/g, '-');

export const formatPhoneNumber = (raw) => (raw ?? '').replace(/\s+/g, '').replace(/^0/, '+44');