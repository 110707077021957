export const fullProjection = `mutation($input: AwyesContentRemoval!) {
  deleteProductTypePage(input: $input) {
    complete
    dryRun
    refs {
      id
      typeName
      label
      awyesId
    }
    typeName: __typename
  }
}`;

export const resolverField = `deleteProductTypePage`;

const deleteProductTypePage = {
  fullProjection,
  resolverField,
};

export default deleteProductTypePage;