export const fullProjection = `mutation($input: AwyesContentRemoval!) {
  deleteAwyesImage(input: $input) {
    complete
    dryRun
    refs {
      id
      typeName
      label
      awyesId
    }
    typeName: __typename
  }
}`;

export const resolverField = `deleteAwyesImage`;

const deleteAwyesImage = {
  fullProjection,
  resolverField,
};

export default deleteAwyesImage;