import React from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import AwyesProvider from '@awyes/cms/providers/AwyesProvider';
import CookiePreference from './components/CookiePreference';
import Header from './components/Header';
import Footer from './components/Footer';
import routes from './routes';
import mutations from './graphql/awyes/mutation';
import queries from './graphql/awyes/query';
import types, { pages, settings, products } from './graphql/awyes/type';
import references from './graphql/references.json';
import './scss/App.scss';

const Routes = () => {
  return useRoutes(routes);
};

const App = () => {
  return (
    <div className="app">
      <AwyesProvider
        mutations={mutations}
        queries={queries}
        types={types}
        pages={pages}
        settings={settings}
        products={products}
        references={references}
      >
        <Router>
          <Header />
          <div className="page-container">
            <Routes />
          </div>
          <Footer />
          <CookiePreference />
        </Router>
      </AwyesProvider>
    </div>
  );
};

export default App;