export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getCheckoutPage(query: $query) {
    title
    url
    name
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    description
    keywords
    priority
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getCheckoutPage(query: $query) {
    name
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `getCheckoutPage`;

const getCheckoutPage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getCheckoutPage;