export const fullProjection = `mutation($input: QuickLinkInput!) {
  updateQuickLink(input: $input) {
    name
    link
    image {
      id
      path
      thumbPath
      name
      mime
      width
      height
      size
      eTag
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      typeName: __typename
    }
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: QuickLinkInput!) {
  updateQuickLink(input: $input) {
    name
    image {
      id
      path
      thumbPath
      name
      mime
      width
      height
      size
      eTag
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      typeName: __typename
    }
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateQuickLink`;

const updateQuickLink = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateQuickLink;