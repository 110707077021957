export const fullProjection = `mutation($input: AwyesContentRemoval!) {
  deleteProductPage(input: $input) {
    complete
    dryRun
    refs {
      id
      typeName
      label
      awyesId
    }
    typeName: __typename
  }
}`;

export const resolverField = `deleteProductPage`;

const deleteProductPage = {
  fullProjection,
  resolverField,
};

export default deleteProductPage;