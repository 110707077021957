const fields = [
  {
    "name": "name",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": true,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "link",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "image",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "AwyesImage"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": true,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "id",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "ID"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "hidden",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Boolean"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "revision",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "placeholders",
    "required": false,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "PlaceholderInput"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  }
];

const validators = {
  name: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [name]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [name]';
    return true;
  },
  link: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [link]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [link]';
    if (!/^(?:https?)|\//.test(value)) return 'Please enter a valid link';
    return true;
  },
  image: (value) => {
    if (value?.constructor !== Object) return 'An Object is expected for [image]';
    if (value.id?.constructor !== String) return 'A String is expected for the id of [image]';
    if (!value.id.trim().length) return 'Please enter a value for the id of [image]';
    if (!/^(AwyesImage)$/.test(`${value.typeName ?? ''}`)) return 'AwyesImage expected for [image]';
    return true;
  },
  id: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [id]';
    if (!value.trim().length) return 'Please enter a value for [id]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [id]';
    return true;
  },
  hidden: (value) => {
    if (value?.constructor !== Boolean) return 'A Boolean is expected for [hidden]';
    return true;
  },
  revision: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [revision]';
    return true;
  },
  placeholders: (values) => {
    const checkItem = (value) => {
      if (value?.constructor !== Object) return 'An Object is expected for [placeholders]';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  }
};

const type = { fields, validators, updatable: true, deletable: true };

export default type;