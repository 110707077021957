import React, { useEffect, useState } from 'react';
import useNewsletter from '@awyes/cms/hooks/useNewsletter';
import useGetContactDetails from '../hooks/awyes/useGetContactDetails';
import { formatPhoneNumber } from '../lib/formatters';
import useGetOpeningTimes from '../hooks/awyes/useGetOpeningTimes';
import SocialMedia from './SocialMedia';
import PrivacyPolicy from './PrivacyPolicy';
import ReturnsPolicy from './ReturnsPolicy';

import '../scss/Footer.scss';
import classNames from "classnames";
import Spinner from "./Spinner";

const FooterSection = ({ awyesId, title, children, className = '' }) => {
  return (
    <section data-awyesid={awyesId} className={`footer__section ${className}`}>
      <h4 className="footer__section__title">{title}</h4>
      <div className="footer__section__content">
        {children}
      </div>
    </section>
  );
};

const Footer = () => {
  const [{ data: contactDetails = {} }, { request: requestContactDetails }] = useGetContactDetails();
  const [{ data: openingTimes = {} }, { request: requestOpeningTimes }] = useGetOpeningTimes();
  const [{
    error: newsletterError,
    loading: newsletterLoading,
    complete: newsletterComplete,
    subscribed
  }, { request: requestNewsletter }] = useNewsletter();
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [returnsPolicyOpen, setReturnsPolicyOpen] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState('');

  useEffect(() => {
    requestContactDetails();
    return () => {
      requestContactDetails.aborted = true;
    };
  }, [requestContactDetails]);

  useEffect(() => {
    requestOpeningTimes();
    return () => {
      requestOpeningTimes.aborted = true;
    };
  }, [requestOpeningTimes]);

  return (
    <footer className="footer">
      <div className="footer__content">
        <FooterSection awyesId={contactDetails?.awyesId} title="Customer Services">
          {contactDetails.phone?.length || contactDetails.email?.length ?
            <address className="footer__group">
              <p>Contact Us:</p>
              {contactDetails.phone?.length ?
                <p>T: <a className="footer__link" rel="noreferrer"
                         href={`tel:${formatPhoneNumber(contactDetails.phone)}`}>{contactDetails.phone}</a>
                </p> : null}
              {contactDetails.email?.length ?
                <p>E: <a className="footer__link" rel="noreferrer"
                         href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a></p> : null}
            </address>
            : null
          }
          {contactDetails.address?.length ?
            <address className="footer__group">
              <p>Location:</p>
              {contactDetails.address.split(',').filter(Boolean).map((line, i) => <p key={i}>{line}</p>)}
            </address>
            : null
          }
          {contactDetails.mapLink?.length ?
            <div className="footer__group">
              <p>
                <a className="footer__link footer__link--with-arrow" href={contactDetails.mapLink} rel="noreferrer"
                   target="_blank">Get Directions</a>
              </p>
            </div>
            : null
          }
        </FooterSection>
        <FooterSection awyesId={openingTimes?.awyesId} title="Our Company">
          {openingTimes.mondayToFriday?.length ?
            <div className="footer__group">
              <p>Monday - Friday</p>
              <p>{openingTimes.mondayToFriday}</p>
            </div>
            : null
          }
          {openingTimes.saturday?.length ?
            <div className="footer__group">
              <p>Saturday</p>
              <p>{openingTimes.saturday}</p>
            </div>
            : null
          }
          {openingTimes.sunday?.length ?
            <div className="footer__group">
              <p>Sunday</p>
              <p>{openingTimes.sunday}</p>
            </div>
            : null
          }
          <div className="footer__group">
            <p>
              <button className="footer__link footer__link--with-arrow" onClick={(e) => {
                e.preventDefault();
                setPrivacyPolicyOpen(false);
                setReturnsPolicyOpen(true);
              }}
              >Returns Policy
              </button>
              {returnsPolicyOpen ? <ReturnsPolicy onClose={() => setReturnsPolicyOpen(false)}/> : null}
            </p>
            <p>
              <button className="footer__link footer__link--with-arrow" onClick={(e) => {
                e.preventDefault();
                setReturnsPolicyOpen(false);
                setPrivacyPolicyOpen(true);
              }}>Privacy Policy
              </button>
              {privacyPolicyOpen ? <PrivacyPolicy onClose={() => setPrivacyPolicyOpen(false)}/> : null}
            </p>
          </div>
          <div className="footer__group"><p>Registered Company Number: 01326863</p></div>
        </FooterSection>
        <FooterSection title="Be Our Friend" className={classNames('footer__newsletter', {
          'footer__newsletter--error': newsletterError || (newsletterComplete && !subscribed),
        })}>
          <div className="footer__group">
            <p className="footer__newsletter__message">{newsletterError || (newsletterComplete && !subscribed) ?
              `Sorry there was a problem, please check your email and try again.` :
              newsletterComplete && subscribed ?
                'Thank you for joining to our newsletter!' :
                'Be the first to know about exciting new collections, special offers and much more.'
            }
            </p>
          </div>
          {!newsletterLoading && !(newsletterComplete && subscribed) ?
            <form className="footer__group">
              <input
                className="footer__newsletter__field"
                placeholder="Enter your email"
                value={newsletterEmail}
                onChange={(e) => setNewsletterEmail(e.target.value)}
              />
              <button
                className="footer__newsletter__button"
                onClick={(e) => {
                  e.preventDefault();
                  requestNewsletter(newsletterEmail);
                }}
              >Join</button>
            </form>
          : null}
          {newsletterLoading ? <Spinner /> : null}
          <SocialMedia/>
          <p className="footer__recaptcha">
            This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" rel="noreferrer" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" rel="noreferrer" target="_blank">Terms of Service</a> apply.
          </p>
        </FooterSection>
      </div>
    </footer>
  );
};

export default Footer;