export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllQuickLink(query: $query) {
    nextToken
    items {
      ... on QuickLink {
        name
        link
        image {
          id
          path
          thumbPath
          name
          mime
          width
          height
          size
          eTag
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          typeName: __typename
        }
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllQuickLink(query: $query) {
    nextToken
    items {
      ... on QuickLink {
        name
        image {
          id
          path
          thumbPath
          name
          mime
          width
          height
          size
          eTag
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          typeName: __typename
        }
        id
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllQuickLink`;

const getAllQuickLink = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllQuickLink;