import React from 'react';
import Notice from "../components/Notice";

const ErrorPage = () => {
  return (
    <Notice header=":-(" messages={[
      'Sorry, there was a problem loading the page.',
      'Please try again or use the option below to browse our other products.',
    ]}/>
  );
};

export default ErrorPage;