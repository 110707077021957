import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import '../scss/Policy.scss';
import classNames from 'classnames';

const Policy = ({ header, policy, onClose }) => {
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.code === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onClose]);

  return createPortal(
    <aside className="policy">
      <h3 className="policy__header">
        <span>{header}</span>
        <button className="policy__close" onClick={() => onClose()} />
      </h3>
      <div className="policy__content">
        { policy?.length ? policy.split('\n').filter(Boolean).map((line, index) => <p key={index} className={classNames({ 'policy__content__header': line.trim().startsWith('#') })}>{line.replace(/^\s?#+/, '')}</p>) : null }
      </div>
    </aside>
  , document.body);
};

export default Policy;
