export const fullProjection = `mutation($input: HeroInput!) {
  updateHero(input: $input) {
    name
    image {
      id
      path
      thumbPath
      name
      mime
      width
      height
      size
      eTag
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      typeName: __typename
    }
    link
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: HeroInput!) {
  updateHero(input: $input) {
    name
    image {
      id
      path
      thumbPath
      name
      mime
      width
      height
      size
      eTag
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      typeName: __typename
    }
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateHero`;

const updateHero = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateHero;