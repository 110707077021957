export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getQuickLink(query: $query) {
    name
    link
    image {
      id
      path
      thumbPath
      name
      mime
      width
      height
      size
      eTag
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      typeName: __typename
    }
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getQuickLink(query: $query) {
    name
    image {
      id
      path
      thumbPath
      name
      mime
      width
      height
      size
      eTag
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      typeName: __typename
    }
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `getQuickLink`;

const getQuickLink = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getQuickLink;