import React, { useCallback, useEffect, useState } from 'react';
import useNav from '@awyes/cms/hooks/useNav';
import classNames from "classnames";
import NavItem from './NavItem';

import '../scss/MainNav.scss';

const sortNav = (({ priority: a } = {}, { priority: b } = {}) => (a ?? 0) - (b ?? 0));

const MainNav = () => {
  const [nav, setNav] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [{ complete, data }, { request }] = useNav();

  useEffect(() => {
    request();
    return () => {
      request.aborted = true;
    };
  }, [request]);

  useEffect(() => {
    if (complete) {
      const groups = (data.items ?? []).reduce((obj, { name, url, priority }) => {
        const [category, productType] = url.replace(/^\//, '').split('/');
        if (category?.length) {
          obj[category] = obj[category] ?? { children: [] };
          if (productType?.length) {
            obj[category].children.push({
              name,
              url,
              priority,
            });
            obj[category].children.sort(sortNav);
          } else {
            obj[category].name = name;
            obj[category].url = url;
            obj[category].priority = priority;
          }
        }
        return obj;
      }, {});
      const newNav = Object.keys(groups).map((key) => groups[key]).filter(({ url }) => url?.length).sort(sortNav);
      setNav(newNav);
    }
  }, [complete, data]);

  const onClick = useCallback(() => {
    if (selected) {
      setSelected(undefined)
    } else {
      setOpen((open) => !open);
    }
  }, [selected]);

  const onClose = useCallback(() => {
    setSelected(undefined);
    setOpen(false);
  }, []);

  const onSelect = useCallback((obj) => {
    setSelected(obj);
  }, []);

  return (
    <nav className={classNames('main-nav', {'main-nav--open': open})}>
      <button className="main-nav__burger" onClick={() => setOpen(!open)} />
      <div className="main-nav__header">
        <span className="main-nav__title">Shop { selected?.name ? selected.name : 'Menu'}</span>
        <button className="main-nav__burger-close" onClick={onClick} />
      </div>
      <ul className="main-nav__items">
        {nav.filter(({ url }) => !selected || selected.url === url).map((item) => <NavItem
          key={item.url}
          {...item}
          selected={selected}
          onSelect={onSelect}
          onClose={onClose}
          className={classNames('main-nav__items__item', {
            'main-nav__items__item--selected': selected?.url === item.url,
          })}
        />)}
      </ul>
    </nav>
  );
};

export default MainNav;