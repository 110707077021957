import React from 'react';
import classNames from 'classnames';

import '../scss/Button.scss';

const Button = ({ isMain, className, ...props }) => {
  return (
    <button className={classNames('button', { 'button--main': isMain, [className]: className?.length })} {...props} />
  );
};

export default Button;