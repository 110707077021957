const fields = [
  {
    "name": "title",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": true,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "url",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": true,
    "hidden": false,
    "formatters": [
      "SLUGIFY"
    ]
  },
  {
    "name": "categoryId",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": true,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "productType",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "ProductTypePage"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "id",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "ID"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "hidden",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Boolean"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "revision",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "placeholders",
    "required": false,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "PlaceholderInput"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "name",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": true,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "code",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "images",
    "required": true,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "AwyesImage"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "price",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "wasPrice",
    "required": false,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "promoted",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Boolean"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "description",
    "required": false,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": true,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "keywords",
    "required": false,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "priority",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "defaultValue": "0",
    "formatters": []
  }
];

const validators = {
  title: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [title]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [title]';
    if (value.length < 1) return 'Value too short for [title], min is 1';
    if (value.length > 255) return 'Value too long for [title], max is 255';
    return true;
  },
  url: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [url]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [url]';
    return true;
  },
  categoryId: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [categoryId]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [categoryId]';
    return true;
  },
  productType: (value) => {
    if (value?.constructor !== Object) return 'An Object is expected for [productType]';
    if (value.id?.constructor !== String) return 'A String is expected for the id of [productType]';
    if (!value.id.trim().length) return 'Please enter a value for the id of [productType]';
    if (!/^(ProductTypePage)$/.test(`${value.typeName ?? ''}`)) return 'ProductTypePage expected for [productType]';
    return true;
  },
  id: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [id]';
    if (!value.trim().length) return 'Please enter a value for [id]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [id]';
    return true;
  },
  hidden: (value) => {
    if (value?.constructor !== Boolean) return 'A Boolean is expected for [hidden]';
    return true;
  },
  revision: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [revision]';
    return true;
  },
  placeholders: (values) => {
    const checkItem = (value) => {
      if (value?.constructor !== Object) return 'An Object is expected for [placeholders]';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  },
  name: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [name]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [name]';
    if (value.length < 1) return 'Value too short for [name], min is 1';
    if (value.length > 100) return 'Value too long for [name], max is 100';
    return true;
  },
  code: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [code]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [code]';
    if (value.length < 1) return 'Value too short for [code], min is 1';
    return true;
  },
  images: (values) => {
    if (values.constructor !== Array) return 'An Array is expected for [images]';
    if (values.length < 1) return 'Please add more items to [images], min is 1';
    const checkItem = (value) => {
      if (value?.constructor !== Object) return 'An Object is expected for [images]';
      if (value.id?.constructor !== String) return 'A String is expected for the id of [images]';
      if (!value.id.trim().length) return 'Please enter a value for the id of [images]';
      if (!/^(AwyesImage)$/.test(`${value.typeName ?? ''}`)) return 'AwyesImage expected for [images]';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  },
  price: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [price]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [price]';
    if (!/^\d{1,3}(?:,\d{3}(?:,\d{3}|\.\d{2}|$)|\d+(?:\.\d{2}|$))*(?:\.\d{1,2})?$/g.test(value)) return 'Please enter a valid price';
    return true;
  },
  wasPrice: (value) => {
    if (value === undefined || value === null) return true;
    if (value?.constructor !== String) return 'A String is expected for [wasPrice]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [wasPrice]';
    if (!/^\d{1,3}(?:,\d{3}(?:,\d{3}|\.\d{2}|$)|\d+(?:\.\d{2}|$))*(?:\.\d{1,2})?$/g.test(value)) return 'Please enter a valid price';
    return true;
  },
  promoted: (value) => {
    if (value?.constructor !== Boolean) return 'A Boolean is expected for [promoted]';
    return true;
  },
  description: (value) => {
    if (value === undefined || value === null) return true;
    if (value?.constructor !== String) return 'A String is expected for [description]';
    if (value.length > 255) return 'Value too long for [description], max is 255';
    return true;
  },
  keywords: (values) => {
    const checkItem = (value) => {
      if (value?.constructor !== String) return 'A String is expected for [keywords]';
      if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [keywords]';
      if (value.length < 1) return 'Value too short for [keywords], min is 1';
      if (value.length > 50) return 'Value too long for [keywords], max is 50';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  },
  priority: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [priority]';
    return true;
  }
};

const placeholders = {
  "fields": {
    "title": [
      "$productType.category.name",
      " - ",
      "$productType.name",
      " - ",
      "$name"
    ],
    "url": [
      "/",
      "$productType.category.name",
      "/",
      "$productType.name",
      "/",
      "$name",
      "-",
      "$code"
    ],
    "categoryId": [
      "$productType.category.awyesId"
    ]
  },
  "values": {
    "$productType.category.name": {
      "type": "query",
      "field": "productType",
      "resolverField": "getProductTypePage",
      "projection": "query getProductTypePage($query: AwyesContentSingleQuery!) {\n  getProductTypePage(query: $query) {\n    category {\n      name\n      awyesId\n      revision\n    }\n    awyesId\n    revision\n  }}",
      "valuePath": [
        "category",
        "name"
      ],
      "cachePaths": [
        [
          "awyesId"
        ],
        [
          "category",
          "awyesId"
        ]
      ]
    },
    "$productType.name": {
      "type": "query",
      "field": "productType",
      "resolverField": "getProductTypePage",
      "projection": "query getProductTypePage($query: AwyesContentSingleQuery!) {\n  getProductTypePage(query: $query) {\n    name\n    awyesId\n    revision\n  }}",
      "valuePath": [
        "name"
      ],
      "cachePaths": [
        [
          "awyesId"
        ]
      ]
    },
    "$name": {
      "type": "field",
      "field": "name"
    },
    "$code": {
      "type": "field",
      "field": "code"
    },
    "$productType.category.awyesId": {
      "type": "query",
      "field": "productType",
      "resolverField": "getProductTypePage",
      "projection": "query getProductTypePage($query: AwyesContentSingleQuery!) {\n  getProductTypePage(query: $query) {\n    category {\n      awyesId\n      revision\n    }\n    awyesId\n    revision\n  }}",
      "valuePath": [
        "category",
        "awyesId"
      ],
      "cachePaths": [
        [
          "awyesId"
        ],
        [
          "category",
          "awyesId"
        ]
      ]
    }
  },
  "formatters": {
    "url": [
      "PARAMIFY"
    ]
  }
};

const route = "/:categoryName/:productType/:name";

const type = { fields, validators, placeholders, route, updatable: true, deletable: true };

export default type;