import React, { useEffect } from 'react';
import useGetSocialMedia from '../hooks/awyes/useGetSocialMedia';

import '../scss/SocialMedia.scss';

const SocialMedia = () => {
  const [{ data: { awyesId, facebook, instagram, ebay, twitter } = {} }, { request }] = useGetSocialMedia();

  useEffect(() => {
    request();
    return () => {
      request.aborted = true;
    };
  }, [request]);

  return (
    <ul data-awyesid={awyesId} className="social-media">
      { facebook?.length ?
        <li className="social-media__platform">
          <a className="social-media__platform__link" href={facebook} rel="noreferrer" target="_blank">
            <img className="social-media__platform__image" src="/images/icon_facebook.svg" width="36" height="36" alt="Facebook" />
          </a>
        </li>
        : null
      }
      { instagram?.length ?
        <li className="social-media__platform">
          <a className="social-media__platform__link" href={instagram} rel="noreferrer" target="_blank">
            <img className="social-media__platform__image" src="/images/icon_instagram.svg" width="36" height="36" alt="Instagram" />
          </a>
        </li>
        : null
      }
      { ebay?.length ?
        <li className="social-media__platform">
          <a className="social-media__platform__link" href={ebay} rel="noreferrer" target="_blank">
            <img className="social-media__platform__image" src="/images/icon_ebay.svg" width="36" height="36" alt="eBay" />
          </a>
        </li>
        : null
      }
      { twitter?.length ?
        <li className="social-media__platform">
          <a className="social-media__platform__link" href={twitter} rel="noreferrer" target="_blank">
            <img className="social-media__platform__image" src="/images/icon_twitter.svg" width="36" height="36" alt="Twitter" />
          </a>
        </li>
        : null
      }
    </ul>
  );
};

export default SocialMedia;