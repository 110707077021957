import React, { useEffect } from 'react';
import useGetContactDetails from '../hooks/awyes/useGetContactDetails';
import { formatPhoneNumber } from '../lib/formatters';

import '../scss/ContactNumber.scss';

const ContactNumber = () => {
  const [{ data: { awyesId, phone } = {} }, { request }] = useGetContactDetails();

  useEffect(() => {
    request();
    return () => {
      request.aborted = true;
    };
  }, [request]);

  return (
    <div className="contact-number">
      { phone?.length ? <a data-awyesid={awyesId} className="contact-number__link" rel="noreferrer" href={`tel:${formatPhoneNumber(phone)}`}>
        <span className="contact-number__value">{phone}</span>
      </a> : null }
    </div>
  );
};

export default ContactNumber;