export const fullProjection = `mutation($input: ProductTypePageInput!) {
  updateProductTypePage(input: $input) {
    title
    url
    category {
      title
      url
      name
      header
      id
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      description
      keywords
      priority
      typeName: __typename
    }
    name
    header
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    description
    keywords
    priority
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: ProductTypePageInput!) {
  updateProductTypePage(input: $input) {
    name
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateProductTypePage`;

const updateProductTypePage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateProductTypePage;