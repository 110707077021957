export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllSiteBanner(query: $query) {
    nextToken
    items {
      ... on SiteBanner {
        message
        link
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllSiteBanner(query: $query) {
    nextToken
    items {
      ... on SiteBanner {
        id
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllSiteBanner`;

const getAllSiteBanner = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllSiteBanner;