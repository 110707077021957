import updateQuickLink from './updateQuickLink';
import deleteQuickLink from './deleteQuickLink';
import updateQuickLinks from './updateQuickLinks';
import deleteQuickLinks from './deleteQuickLinks';
import updateHero from './updateHero';
import deleteHero from './deleteHero';
import updateSiteBanner from './updateSiteBanner';
import updateReturnsPolicy from './updateReturnsPolicy';
import updatePrivacyPolicy from './updatePrivacyPolicy';
import updateContactDetails from './updateContactDetails';
import updateOpeningTimes from './updateOpeningTimes';
import updateSocialMedia from './updateSocialMedia';
import updateIndexPage from './updateIndexPage';
import updateWishlistPage from './updateWishlistPage';
import updateCheckoutPage from './updateCheckoutPage';
import updateCategoryPage from './updateCategoryPage';
import deleteCategoryPage from './deleteCategoryPage';
import updateProductTypePage from './updateProductTypePage';
import deleteProductTypePage from './deleteProductTypePage';
import updateProductPage from './updateProductPage';
import deleteProductPage from './deleteProductPage';
import updateAwyesImage from './updateAwyesImage';
import deleteAwyesImage from './deleteAwyesImage';
import updateAwyesNav from './updateAwyesNav';
import updateAwyesOfferCode from './updateAwyesOfferCode';
import deleteAwyesOfferCode from './deleteAwyesOfferCode';

const resolvers = {
  updateQuickLink,
  deleteQuickLink,
  updateQuickLinks,
  deleteQuickLinks,
  updateHero,
  deleteHero,
  updateSiteBanner,
  updateReturnsPolicy,
  updatePrivacyPolicy,
  updateContactDetails,
  updateOpeningTimes,
  updateSocialMedia,
  updateIndexPage,
  updateWishlistPage,
  updateCheckoutPage,
  updateCategoryPage,
  deleteCategoryPage,
  updateProductTypePage,
  deleteProductTypePage,
  updateProductPage,
  deleteProductPage,
  updateAwyesImage,
  deleteAwyesImage,
  updateAwyesNav,
  updateAwyesOfferCode,
  deleteAwyesOfferCode,
};
export default resolvers;