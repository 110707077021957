import getQuickLink from './getQuickLink';
import getAllQuickLink from './getAllQuickLink';
import getQuickLinks from './getQuickLinks';
import getAllQuickLinks from './getAllQuickLinks';
import getHero from './getHero';
import getAllHero from './getAllHero';
import getSiteBanner from './getSiteBanner';
import getAllSiteBanner from './getAllSiteBanner';
import getReturnsPolicy from './getReturnsPolicy';
import getAllReturnsPolicy from './getAllReturnsPolicy';
import getPrivacyPolicy from './getPrivacyPolicy';
import getAllPrivacyPolicy from './getAllPrivacyPolicy';
import getContactDetails from './getContactDetails';
import getAllContactDetails from './getAllContactDetails';
import getOpeningTimes from './getOpeningTimes';
import getAllOpeningTimes from './getAllOpeningTimes';
import getSocialMedia from './getSocialMedia';
import getAllSocialMedia from './getAllSocialMedia';
import getIndexPage from './getIndexPage';
import getAllIndexPage from './getAllIndexPage';
import getWishlistPage from './getWishlistPage';
import getAllWishlistPage from './getAllWishlistPage';
import getCheckoutPage from './getCheckoutPage';
import getAllCheckoutPage from './getAllCheckoutPage';
import getCategoryPage from './getCategoryPage';
import getAllCategoryPage from './getAllCategoryPage';
import getProductTypePage from './getProductTypePage';
import getAllProductTypePage from './getAllProductTypePage';
import getProductPage from './getProductPage';
import getMultiProductPage from './getMultiProductPage';
import getAllProductPage from './getAllProductPage';
import getAwyesImage from './getAwyesImage';
import getAllAwyesImage from './getAllAwyesImage';
import getAwyesNav from './getAwyesNav';
import getAllAwyesNav from './getAllAwyesNav';
import getAwyesOfferCode from './getAwyesOfferCode';
import getAllAwyesOfferCode from './getAllAwyesOfferCode';
import searchProductTypePage from './searchProductTypePage';
import searchProductPage from './searchProductPage';
import searchAwyesPageContentType from './searchAwyesPageContentType';

const resolvers = {
  getQuickLink,
  getAllQuickLink,
  getQuickLinks,
  getAllQuickLinks,
  getHero,
  getAllHero,
  getSiteBanner,
  getAllSiteBanner,
  getReturnsPolicy,
  getAllReturnsPolicy,
  getPrivacyPolicy,
  getAllPrivacyPolicy,
  getContactDetails,
  getAllContactDetails,
  getOpeningTimes,
  getAllOpeningTimes,
  getSocialMedia,
  getAllSocialMedia,
  getIndexPage,
  getAllIndexPage,
  getWishlistPage,
  getAllWishlistPage,
  getCheckoutPage,
  getAllCheckoutPage,
  getCategoryPage,
  getAllCategoryPage,
  getProductTypePage,
  getAllProductTypePage,
  getProductPage,
  getMultiProductPage,
  getAllProductPage,
  getAwyesImage,
  getAllAwyesImage,
  getAwyesNav,
  getAllAwyesNav,
  getAwyesOfferCode,
  getAllAwyesOfferCode,
  searchProductTypePage,
  searchProductPage,
  searchAwyesPageContentType,
};
export default resolvers;