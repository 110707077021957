export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllProductTypePage(query: $query) {
    nextToken
    items {
      ... on ProductTypePage {
        title
        url
        category {
          title
          url
          name
          header
          id
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          description
          keywords
          priority
          typeName: __typename
        }
        name
        header
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        description
        keywords
        priority
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllProductTypePage(query: $query) {
    nextToken
    items {
      ... on ProductTypePage {
        name
        id
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllProductTypePage`;

const getAllProductTypePage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllProductTypePage;