export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getReturnsPolicy(query: $query) {
    policy
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getReturnsPolicy(query: $query) {
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `getReturnsPolicy`;

const getReturnsPolicy = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getReturnsPolicy;