const fields = [
  {
    "name": "id",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "ID"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "code",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": true,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "validFrom",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": [
      "DATETIME"
    ]
  },
  {
    "name": "validTo",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": [
      "DATETIME"
    ]
  },
  {
    "name": "discountAmount",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Float"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "discountType",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "AwyesDiscountType"
    ],
    "isEnum": true,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "productTypes",
    "required": false,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "ProductTypePage"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "minimumOrderAmount",
    "required": false,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "singleUse",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Boolean"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "maxClaims",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "claims",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": true,
    "defaultValue": "0",
    "formatters": []
  },
  {
    "name": "hidden",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Boolean"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "revision",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "placeholders",
    "required": false,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "PlaceholderInput"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  }
];

const validators = {
  id: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [id]';
    if (!value.trim().length) return 'Please enter a value for [id]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [id]';
    return true;
  },
  code: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [code]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [code]';
    if (value.length < 4) return 'Value too short for [code], min is 4';
    if (value.length > 20) return 'Value too long for [code], max is 20';
    if (!/^[A-Z0-9]+$/.test(value)) return 'Please enter a valid code using uppercase letters and numbers';
    return true;
  },
  validFrom: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [validFrom]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [validFrom]';
    if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/.test(value)) return 'Please enter a valid date/time';
    return true;
  },
  validTo: (value, otherValues = {}) => {
    if (value?.constructor !== String) return 'A String is expected for [validTo]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [validTo]';
    if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/.test(value)) return 'Please enter a valid date/time';
    if (!(value > otherValues['validFrom'])) return '[validTo] must be greater than [validFrom]';
    return true;
  },
  discountAmount: (value) => {
    if (isNaN(value) || value?.constructor !== Number) return 'A Float is expected for [discountAmount]';
    return true;
  },
  discountType: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [discountType]';
    if (!['PERCENTAGE', 'FIXED'].includes(value)) return 'Invalid enum value for [discountType]';
    return true;
  },
  productTypes: (values) => {
    const checkItem = (value) => {
      if (value?.constructor !== Object) return 'An Object is expected for [productTypes]';
      if (value.id?.constructor !== String) return 'A String is expected for the id of [productTypes]';
      if (!value.id.trim().length) return 'Please enter a value for the id of [productTypes]';
      if (!/^(ProductTypePage)$/.test(`${value.typeName ?? ''}`)) return 'ProductTypePage expected for [productTypes]';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  },
  minimumOrderAmount: (value) => {
    if (value === undefined || value === null) return true;
    if (value?.constructor !== String) return 'A String is expected for [minimumOrderAmount]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [minimumOrderAmount]';
    if (!/^\d{1,3}(?:,\d{3}(?:,\d{3}|\.\d{2}|$)|\d+(?:\.\d{2}|$))*(?:\.\d{1,2})?$/g.test(value)) return 'Please enter a valid amount';
    return true;
  },
  singleUse: (value) => {
    if (value?.constructor !== Boolean) return 'A Boolean is expected for [singleUse]';
    return true;
  },
  maxClaims: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [maxClaims]';
    return true;
  },
  claims: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [claims]';
    return true;
  },
  hidden: (value) => {
    if (value?.constructor !== Boolean) return 'A Boolean is expected for [hidden]';
    return true;
  },
  revision: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [revision]';
    return true;
  },
  placeholders: (values) => {
    const checkItem = (value) => {
      if (value?.constructor !== Object) return 'An Object is expected for [placeholders]';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  }
};

const enums = {
  "discountType": {
    "PERCENTAGE": "PERCENTAGE",
    "FIXED": "FIXED"
  }
};

const type = { fields, validators, enums, updatable: true, deletable: true };

export default type;