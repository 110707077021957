import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from "classnames";

import '../scss/NavItem.scss';

const NavItem = ({ name, url, selected, onSelect, onClose, children, className = '', linkClassName = '' }) => {
  return (
    <li className={classNames('nav-item', { [className]: className?.length })}>
      <NavLink to={url} onClick={onClose} className={classNames('nav-item__link', {[linkClassName]: linkClassName?.length})}>{name}</NavLink>
      { children?.length ?
        <>
          <ul className={classNames('nav-item-children', {
            'nav-item-children--open': selected?.url === url,
          })}>
            {children.map(({ children, ...item }) => item).map(({ url, ...props }) => <NavItem key={url} url={url} {...props} selected={selected} onSelect={onSelect} onClose={onClose} />)}
          </ul>
          <button className="nav-item__select" onClick={() => onSelect({ name, url })} />
        </>
        : null
      }
    </li>
  );
};

export default NavItem;