import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import useGetSiteBanner from '../hooks/awyes/useGetSiteBanner';

import '../scss/SiteBanner.scss';

const SiteBanner = () => {
  const [{ data: { awyesId, message, link } = {} }, { request }] = useGetSiteBanner();

  useEffect(() => {
    request();
    return () => {
      request.aborted = true;
    };
  }, [request]);

  return (
    message?.length && link?.length ?
      <div data-awyesid={awyesId} className="site-banner">
        <NavLink className="site-banner__link" to={link}>
          <div className="site-banner__content">
            {message ?? ''}
          </div>
        </NavLink>
      </div>
      :
      <div data-awyesid={awyesId} className="site-banner">
        <div className="site-banner__content">
          {message ?? ''}
        </div>
      </div>
  );
};

export default SiteBanner;