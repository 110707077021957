export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getAwyesNav(query: $query) {
    items {
      id
      typeName
      name
      url
      priority
    }
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getAwyesNav(query: $query) {
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `getAwyesNav`;

const getAwyesNav = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAwyesNav;