export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllIndexPage(query: $query) {
    nextToken
    items {
      ... on IndexPage {
        name
        url
        hero {
          name
          image {
            id
            path
            thumbPath
            name
            mime
            width
            height
            size
            eTag
            createdDate
            updatedDate
            hidden
            awyesId
            revision
            typeName: __typename
          }
          link
          id
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          typeName: __typename
        }
        quickLinks {
          name
          links {
            name
            link
            image {
              id
              path
              thumbPath
              name
              mime
              width
              height
              size
              eTag
              createdDate
              updatedDate
              hidden
              awyesId
              revision
              typeName: __typename
            }
            id
            createdDate
            updatedDate
            hidden
            awyesId
            revision
            typeName: __typename
          }
          id
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          typeName: __typename
        }
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        title
        description
        keywords
        priority
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllIndexPage(query: $query) {
    nextToken
    items {
      ... on IndexPage {
        id
        awyesId
        revision
        title
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllIndexPage`;

const getAllIndexPage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllIndexPage;