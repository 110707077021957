import React from 'react';

import '../scss/Logo.scss';
import { NavLink } from 'react-router-dom';

const Logo = () => {
  return (
    <h1 className="logo">
      <NavLink to="/" className="logo__link">
        <span className="logo__text">Yorkshire Jewellery Limited</span>
        <img className="logo__image" src="/images/logo.svg" width="157" height="66.5" alt="Yorkshire Jewellery Limited" />
      </NavLink>
    </h1>
  );
};

export default Logo;