import React, { useEffect } from 'react';
import useGetReturnsPolicy from '../hooks/awyes/useGetReturnsPolicy';
import Policy from './Policy';

const ReturnsPolicy = ({ onClose }) => {
  const [{ data: { policy } = {} }, { request }] = useGetReturnsPolicy()

  useEffect(() => {
    request();
    return () => {
      request.aborted = true;
    };
  }, [request]);

  return (
    policy ? <Policy header="Returns Policy" policy={policy} onClose={onClose} /> : null
  )
};

export default ReturnsPolicy;