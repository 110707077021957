import React, { useEffect } from 'react';
import useGetPrivacyPolicy from '../hooks/awyes/useGetPrivacyPolicy';
import Policy from './Policy';

const PrivacyPolicy = ({ onClose }) => {
  const [{ data: { policy } = {} }, { request }] = useGetPrivacyPolicy();

  useEffect(() => {
    request();
    return () => {
      request.aborted = true;
    };
  }, [request]);

  return (
    policy ? <Policy header="Privacy Policy" policy={policy} onClose={onClose} /> : null
  )
};

export default PrivacyPolicy;