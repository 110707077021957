export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getOpeningTimes(query: $query) {
    mondayToFriday
    saturday
    sunday
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getOpeningTimes(query: $query) {
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `getOpeningTimes`;

const getOpeningTimes = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getOpeningTimes;