export const fullProjection = `mutation($input: QuickLinksInput!) {
  updateQuickLinks(input: $input) {
    name
    links {
      name
      link
      image {
        id
        path
        thumbPath
        name
        mime
        width
        height
        size
        eTag
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
      id
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      typeName: __typename
    }
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: QuickLinksInput!) {
  updateQuickLinks(input: $input) {
    name
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateQuickLinks`;

const updateQuickLinks = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateQuickLinks;