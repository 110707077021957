export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getCategoryPage(query: $query) {
    title
    url
    name
    header
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    description
    keywords
    priority
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getCategoryPage(query: $query) {
    name
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `getCategoryPage`;

const getCategoryPage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getCategoryPage;