import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import useWishlist from '@awyes/cms/hooks/useWishlist';
import useBasket from '@awyes/cms/hooks/useBasket';

import '../scss/ShopNav.scss';

const ShopNav = () => {
  const [{ wishlist }] = useWishlist();
  const [{ basket }] = useBasket();

  return (
    <nav className="shop-nav">
      <ul className="shop-nav__items">
        <li className="shop-nav__items__item">
          <NavLink title="Wishlist" to="/wishlist" className={classNames('shop-nav__items__link shop-nav__items__link--wishlist', {
            'shop-nav__items__link--wishlist-selected': wishlist.length,
          })}>
            { wishlist.length ? <span className="shop-nav__items__count">{wishlist.length}</span> : null }
          </NavLink>
        </li>
        <li className="shop-nav__items__item">
          <NavLink  title="Checkout" to="/checkout" className={classNames('shop-nav__items__link shop-nav__items__link--checkout', {
            'shop-nav__items__link--checkout-selected': basket.length,
          })}>
            { basket.length ? <span className="shop-nav__items__count">{basket.length}</span> : null }
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default ShopNav;