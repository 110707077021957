import React from 'react';
import Logo from './Logo';
import MainNav from './MainNav';
import SiteBanner from './SiteBanner';
import ShopNav from './ShopNav';
import ContactNumber from './ContactNumber';

import '../scss/Header.scss';

const Header = () => {
  return (
    <header className="header">
      <div className="header__content">
        <ContactNumber />
        <Logo />
        <ShopNav />
      </div>
      <MainNav />
      <SiteBanner />
    </header>
  );
};

export default Header;