export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getAwyesImage(query: $query) {
    id
    path
    thumbPath
    name
    mime
    width
    height
    size
    eTag
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getAwyesImage(query: $query) {
    id
    name
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `getAwyesImage`;

const getAwyesImage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAwyesImage;