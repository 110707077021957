export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getProductPage(query: $query) {
    title
    url
    categoryId
    productType {
      title
      url
      category {
        title
        url
        name
        header
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        description
        keywords
        priority
        typeName: __typename
      }
      name
      header
      id
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      description
      keywords
      priority
      typeName: __typename
    }
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    name
    code
    images {
      id
      path
      thumbPath
      name
      mime
      width
      height
      size
      eTag
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      typeName: __typename
    }
    price
    wasPrice
    promoted
    description
    keywords
    priority
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getProductPage(query: $query) {
    id
    awyesId
    revision
    name
    typeName: __typename
  }
}`;

export const resolverField = `getProductPage`;

const getProductPage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getProductPage;