export const fullProjection = `mutation($input: ContactDetailsInput!) {
  updateContactDetails(input: $input) {
    phone
    whatsapp
    email
    address
    mapLink
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: ContactDetailsInput!) {
  updateContactDetails(input: $input) {
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateContactDetails`;

const updateContactDetails = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateContactDetails;