export const fullProjection = `query($where: AwyesPageContentTypeSearchInput!) {
  searchAwyesPageContentType(where: $where) {
    nextToken
    items {
      ... on IndexPage {
        name
        url
        hero {
          name
          image {
            id
            path
            thumbPath
            name
            mime
            width
            height
            size
            eTag
            createdDate
            updatedDate
            hidden
            awyesId
            revision
            typeName: __typename
          }
          link
          id
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          typeName: __typename
        }
        quickLinks {
          name
          links {
            name
            link
            image {
              id
              path
              thumbPath
              name
              mime
              width
              height
              size
              eTag
              createdDate
              updatedDate
              hidden
              awyesId
              revision
              typeName: __typename
            }
            id
            createdDate
            updatedDate
            hidden
            awyesId
            revision
            typeName: __typename
          }
          id
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          typeName: __typename
        }
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        title
        description
        keywords
        priority
        typeName: __typename
      }
      ... on WishlistPage {
        title
        url
        name
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        description
        keywords
        priority
        typeName: __typename
      }
      ... on CheckoutPage {
        title
        url
        name
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        description
        keywords
        priority
        typeName: __typename
      }
      ... on CategoryPage {
        title
        url
        name
        header
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        description
        keywords
        priority
        typeName: __typename
      }
      ... on ProductTypePage {
        title
        url
        category {
          title
          url
          name
          header
          id
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          description
          keywords
          priority
          typeName: __typename
        }
        name
        header
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        description
        keywords
        priority
        typeName: __typename
      }
      ... on ProductPage {
        title
        url
        categoryId
        productType {
          title
          url
          category {
            title
            url
            name
            header
            id
            createdDate
            updatedDate
            hidden
            awyesId
            revision
            description
            keywords
            priority
            typeName: __typename
          }
          name
          header
          id
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          description
          keywords
          priority
          typeName: __typename
        }
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        name
        code
        images {
          id
          path
          thumbPath
          name
          mime
          width
          height
          size
          eTag
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          typeName: __typename
        }
        price
        wasPrice
        promoted
        description
        keywords
        priority
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `searchAwyesPageContentType`;

const searchAwyesPageContentType = {
  fullProjection,
  resolverField,
};

export default searchAwyesPageContentType;