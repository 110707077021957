import QuickLink from './QuickLink';
import QuickLinks from './QuickLinks';
import Hero from './Hero';
import SiteBanner from './SiteBanner';
import ReturnsPolicy from './ReturnsPolicy';
import PrivacyPolicy from './PrivacyPolicy';
import ContactDetails from './ContactDetails';
import OpeningTimes from './OpeningTimes';
import SocialMedia from './SocialMedia';
import IndexPage from './IndexPage';
import WishlistPage from './WishlistPage';
import CheckoutPage from './CheckoutPage';
import CategoryPage from './CategoryPage';
import ProductTypePage from './ProductTypePage';
import ProductPage from './ProductPage';
import AwyesImage from './AwyesImage';
import AwyesNav from './AwyesNav';
import AwyesOfferCode from './AwyesOfferCode';

const types = {
  QuickLink,
  QuickLinks,
  Hero,
  SiteBanner,
  ReturnsPolicy,
  PrivacyPolicy,
  ContactDetails,
  OpeningTimes,
  SocialMedia,
  IndexPage,
  WishlistPage,
  CheckoutPage,
  CategoryPage,
  ProductTypePage,
  ProductPage,
  AwyesImage,
  AwyesNav,
  AwyesOfferCode,
};
export default types;


export const pages = {
  IndexPage,
  WishlistPage,
  CheckoutPage,
  CategoryPage,
  ProductTypePage,
  ProductPage,
};


export const settings = {
  ReturnsPolicy,
  PrivacyPolicy,
  ContactDetails,
  OpeningTimes,
  SocialMedia,
};


export const products = {
  ProductPage,
};
