export const fullProjection = `mutation($input: CheckoutPageInput!) {
  updateCheckoutPage(input: $input) {
    title
    url
    name
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    description
    keywords
    priority
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: CheckoutPageInput!) {
  updateCheckoutPage(input: $input) {
    name
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateCheckoutPage`;

const updateCheckoutPage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateCheckoutPage;