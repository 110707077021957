export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getSocialMedia(query: $query) {
    facebook
    instagram
    ebay
    twitter
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getSocialMedia(query: $query) {
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `getSocialMedia`;

const getSocialMedia = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getSocialMedia;