export const fullProjection = `mutation($input: AwyesContentRemoval!) {
  deleteAwyesOfferCode(input: $input) {
    complete
    dryRun
    refs {
      id
      typeName
      label
      awyesId
    }
    typeName: __typename
  }
}`;

export const resolverField = `deleteAwyesOfferCode`;

const deleteAwyesOfferCode = {
  fullProjection,
  resolverField,
};

export default deleteAwyesOfferCode;