import React from 'react';
import classNames from "classnames";

import '../scss/Spinner.scss';

const Spinner = ({ className }) => {
  return (
    <div className={classNames('spinner-container', {
      [className]: className?.length
    })}>
      <div className="spinner" />
    </div>
  );
};

export default Spinner;