import { useCallback } from 'react';
import useGetType from '@awyes/cms/hooks/useGetType';
import { fullProjection, resolverField } from '../../graphql/awyes/query/getOpeningTimes';

const useGetOpeningTimes = (projection = fullProjection) => {
  const [state, { request }] = useGetType({ staticId: 'opening-times', projection, typeName: 'OpeningTimes', resolverField });
  const requestWithVars = useCallback(async function doRequest() {
    doRequest.aborted = false;
    await request({}, () => doRequest.aborted);
  }, [request]);
  return [state, { request: requestWithVars }];
};

export default useGetOpeningTimes;
