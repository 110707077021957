export const fullProjection = `mutation($input: AwyesImageInput!) {
  updateAwyesImage(input: $input) {
    id
    path
    thumbPath
    name
    mime
    width
    height
    size
    eTag
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: AwyesImageInput!) {
  updateAwyesImage(input: $input) {
    id
    name
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateAwyesImage`;

const updateAwyesImage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateAwyesImage;