const fields = [
  {
    "name": "items",
    "required": true,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "AwyesNavItemInput"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "id",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "ID"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": true,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "hidden",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Boolean"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "revision",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "placeholders",
    "required": false,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "PlaceholderInput"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  }
];

const validators = {
  items: (values) => {
    if (values.constructor !== Array) return 'An Array is expected for [items]';
    const checkItem = (value) => {
      if (value?.constructor !== Object) return 'An Object is expected for [items]';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  },
  id: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [id]';
    if (!value.trim().length) return 'Please enter a value for [id]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [id]';
    return true;
  },
  hidden: (value) => {
    if (value?.constructor !== Boolean) return 'A Boolean is expected for [hidden]';
    return true;
  },
  revision: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [revision]';
    return true;
  },
  placeholders: (values) => {
    const checkItem = (value) => {
      if (value?.constructor !== Object) return 'An Object is expected for [placeholders]';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  }
};

const type = { fields, validators, id: 'awyes-nav', updatable: true, deletable: false };

export default type;