export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getIndexPage(query: $query) {
    name
    url
    hero {
      name
      image {
        id
        path
        thumbPath
        name
        mime
        width
        height
        size
        eTag
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
      link
      id
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      typeName: __typename
    }
    quickLinks {
      name
      links {
        name
        link
        image {
          id
          path
          thumbPath
          name
          mime
          width
          height
          size
          eTag
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          typeName: __typename
        }
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
      id
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      typeName: __typename
    }
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    title
    description
    keywords
    priority
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getIndexPage(query: $query) {
    id
    awyesId
    revision
    title
    typeName: __typename
  }
}`;

export const resolverField = `getIndexPage`;

const getIndexPage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getIndexPage;