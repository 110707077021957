export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllPrivacyPolicy(query: $query) {
    nextToken
    items {
      ... on PrivacyPolicy {
        policy
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllPrivacyPolicy(query: $query) {
    nextToken
    items {
      ... on PrivacyPolicy {
        id
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllPrivacyPolicy`;

const getAllPrivacyPolicy = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllPrivacyPolicy;