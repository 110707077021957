export const fullProjection = `mutation($input: AwyesContentRemoval!) {
  deleteQuickLink(input: $input) {
    complete
    dryRun
    refs {
      id
      typeName
      label
      awyesId
    }
    typeName: __typename
  }
}`;

export const resolverField = `deleteQuickLink`;

const deleteQuickLink = {
  fullProjection,
  resolverField,
};

export default deleteQuickLink;