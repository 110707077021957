export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getContactDetails(query: $query) {
    phone
    whatsapp
    email
    address
    mapLink
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getContactDetails(query: $query) {
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `getContactDetails`;

const getContactDetails = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getContactDetails;