export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getProductTypePage(query: $query) {
    title
    url
    category {
      title
      url
      name
      header
      id
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      description
      keywords
      priority
      typeName: __typename
    }
    name
    header
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    description
    keywords
    priority
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getProductTypePage(query: $query) {
    name
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `getProductTypePage`;

const getProductTypePage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getProductTypePage;