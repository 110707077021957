import React, { lazy, Suspense } from 'react';
import useAdmin from '@awyes/cms/hooks/useAdmin';
import { fullProjection, resolverField } from './graphql/awyes/query/searchAwyesPageContentType';
import Fallback from './components/Fallback';
import ErrorPage from './pages/ErrorPage';

const PageBoundary = lazy(() => import('@awyes/cms/components/PageBoundary'));
const CmsProvider = lazy(() => import('@awyes/cms/providers/CmsProvider'));
const Login = lazy(() => import('@awyes/cms/components/Login'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const IndexPage = lazy(() => import('./pages/IndexPage.js'));
const WishlistPage = lazy(() => import('./pages/WishlistPage.js'));
const CheckoutPage = lazy(() => import('./pages/CheckoutPage.js'));
const CategoryPage = lazy(() => import('./pages/CategoryPage.js'));
const ProductTypePage = lazy(() => import('./pages/ProductTypePage.js'));
const ProductPage = lazy(() => import('./pages/ProductPage.js'));

const LazyPage = ({children}) => {
  const [isAdmin] = useAdmin();
  return (
    <Suspense fallback={<Fallback />}>
      { isAdmin ? <CmsProvider>{children}</CmsProvider> : children }
    </Suspense>
  );
};

const routes = [
  { path: '/admin', element: <LazyPage><Login /></LazyPage> },
  { path: '/', element: <LazyPage><PageBoundary page={IndexPage} path="/" projection={fullProjection} resolverField={resolverField} typeName="IndexPage" ErrorPage={ErrorPage} NotFoundPage={NotFoundPage} /></LazyPage> },
  { path: '/wishlist', element: <LazyPage><PageBoundary page={WishlistPage} path="/wishlist" projection={fullProjection} resolverField={resolverField} typeName="WishlistPage" ErrorPage={ErrorPage} NotFoundPage={NotFoundPage} /></LazyPage> },
  { path: '/checkout', element: <LazyPage><PageBoundary page={CheckoutPage} path="/checkout" projection={fullProjection} resolverField={resolverField} typeName="CheckoutPage" ErrorPage={ErrorPage} NotFoundPage={NotFoundPage} /></LazyPage> },
  { path: '/:categoryName', element: <LazyPage><PageBoundary page={CategoryPage} path="/:categoryName" projection={fullProjection} resolverField={resolverField} typeName="CategoryPage" ErrorPage={ErrorPage} NotFoundPage={NotFoundPage} /></LazyPage> },
  { path: '/:categoryName/:productType', element: <LazyPage><PageBoundary page={ProductTypePage} path="/:categoryName/:productType" projection={fullProjection} resolverField={resolverField} typeName="ProductTypePage" ErrorPage={ErrorPage} NotFoundPage={NotFoundPage} /></LazyPage> },
  { path: '/:categoryName/:productType/:name', element: <LazyPage><PageBoundary page={ProductPage} path="/:categoryName/:productType/:name" projection={fullProjection} resolverField={resolverField} typeName="ProductPage" ErrorPage={ErrorPage} NotFoundPage={NotFoundPage} /></LazyPage> },
  { path: '*', element: <LazyPage><NotFoundPage /></LazyPage> },
];

export default routes;