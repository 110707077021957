const fields = [
  {
    "name": "title",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": true,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "url",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": true,
    "hidden": false,
    "formatters": [
      "SLUGIFY"
    ]
  },
  {
    "name": "name",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": true,
    "isCMSImage": false,
    "readOnly": true,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "id",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "ID"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "hidden",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Boolean"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "revision",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "placeholders",
    "required": false,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "PlaceholderInput"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "description",
    "required": false,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": true,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "keywords",
    "required": false,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "priority",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "defaultValue": "0",
    "formatters": []
  }
];

const validators = {
  title: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [title]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [title]';
    if (value.length < 1) return 'Value too short for [title], min is 1';
    if (value.length > 255) return 'Value too long for [title], max is 255';
    return true;
  },
  url: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [url]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [url]';
    return true;
  },
  name: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [name]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [name]';
    if (value.length < 1) return 'Value too short for [name], min is 1';
    if (value.length > 100) return 'Value too long for [name], max is 100';
    return true;
  },
  id: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [id]';
    if (!value.trim().length) return 'Please enter a value for [id]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [id]';
    return true;
  },
  hidden: (value) => {
    if (value?.constructor !== Boolean) return 'A Boolean is expected for [hidden]';
    return true;
  },
  revision: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [revision]';
    return true;
  },
  placeholders: (values) => {
    const checkItem = (value) => {
      if (value?.constructor !== Object) return 'An Object is expected for [placeholders]';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  },
  description: (value) => {
    if (value === undefined || value === null) return true;
    if (value?.constructor !== String) return 'A String is expected for [description]';
    if (value.length > 255) return 'Value too long for [description], max is 255';
    return true;
  },
  keywords: (values) => {
    const checkItem = (value) => {
      if (value?.constructor !== String) return 'A String is expected for [keywords]';
      if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [keywords]';
      if (value.length < 1) return 'Value too short for [keywords], min is 1';
      if (value.length > 50) return 'Value too long for [keywords], max is 50';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  },
  priority: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [priority]';
    return true;
  }
};

const placeholders = {
  "fields": {
    "title": [
      "$name"
    ],
    "url": [
      "/checkout"
    ],
    "name": [
      "Checkout"
    ]
  },
  "values": {
    "$name": {
      "type": "field",
      "field": "name"
    }
  },
  "formatters": {}
};

const route = "/checkout";

const type = { fields, validators, placeholders, id: 'checkout-page', route, updatable: true, deletable: false };

export default type;