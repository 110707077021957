export const fullProjection = `mutation($input: AwyesContentRemoval!) {
  deleteCategoryPage(input: $input) {
    complete
    dryRun
    refs {
      id
      typeName
      label
      awyesId
    }
    typeName: __typename
  }
}`;

export const resolverField = `deleteCategoryPage`;

const deleteCategoryPage = {
  fullProjection,
  resolverField,
};

export default deleteCategoryPage;