export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllQuickLinks(query: $query) {
    nextToken
    items {
      ... on QuickLinks {
        name
        links {
          name
          link
          image {
            id
            path
            thumbPath
            name
            mime
            width
            height
            size
            eTag
            createdDate
            updatedDate
            hidden
            awyesId
            revision
            typeName: __typename
          }
          id
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          typeName: __typename
        }
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllQuickLinks(query: $query) {
    nextToken
    items {
      ... on QuickLinks {
        name
        id
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllQuickLinks`;

const getAllQuickLinks = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllQuickLinks;