export const fullProjection = `mutation($input: OpeningTimesInput!) {
  updateOpeningTimes(input: $input) {
    mondayToFriday
    saturday
    sunday
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: OpeningTimesInput!) {
  updateOpeningTimes(input: $input) {
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateOpeningTimes`;

const updateOpeningTimes = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateOpeningTimes;