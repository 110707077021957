export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllHero(query: $query) {
    nextToken
    items {
      ... on Hero {
        name
        image {
          id
          path
          thumbPath
          name
          mime
          width
          height
          size
          eTag
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          typeName: __typename
        }
        link
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllHero(query: $query) {
    nextToken
    items {
      ... on Hero {
        name
        image {
          id
          path
          thumbPath
          name
          mime
          width
          height
          size
          eTag
          createdDate
          updatedDate
          hidden
          awyesId
          revision
          typeName: __typename
        }
        id
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllHero`;

const getAllHero = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllHero;