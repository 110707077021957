const fields = [
  {
    "name": "id",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "ID"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "path",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "thumbPath",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "name",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": true,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "mime",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "width",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Float"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "height",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Float"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "size",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "eTag",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "hidden",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Boolean"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "revision",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "placeholders",
    "required": false,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "PlaceholderInput"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  }
];

const validators = {
  id: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [id]';
    if (!value.trim().length) return 'Please enter a value for [id]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [id]';
    return true;
  },
  path: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [path]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [path]';
    return true;
  },
  thumbPath: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [thumbPath]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [thumbPath]';
    return true;
  },
  name: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [name]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [name]';
    return true;
  },
  mime: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [mime]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [mime]';
    return true;
  },
  width: (value) => {
    if (isNaN(value) || value?.constructor !== Number) return 'A Float is expected for [width]';
    return true;
  },
  height: (value) => {
    if (isNaN(value) || value?.constructor !== Number) return 'A Float is expected for [height]';
    return true;
  },
  size: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [size]';
    return true;
  },
  eTag: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [eTag]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [eTag]';
    return true;
  },
  hidden: (value) => {
    if (value?.constructor !== Boolean) return 'A Boolean is expected for [hidden]';
    return true;
  },
  revision: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [revision]';
    return true;
  },
  placeholders: (values) => {
    const checkItem = (value) => {
      if (value?.constructor !== Object) return 'An Object is expected for [placeholders]';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  }
};

const type = { fields, validators, updatable: true, deletable: true };

export default type;