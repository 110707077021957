export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllAwyesOfferCode(query: $query) {
    nextToken
    items {
      ... on AwyesOfferCode {
        id
        code
        validFrom
        validTo
        discountAmount
        discountType
        productTypes {
          ... on ProductTypePage {
            title
            url
            category {
              title
              url
              name
              header
              id
              createdDate
              updatedDate
              hidden
              awyesId
              revision
              description
              keywords
              priority
              typeName: __typename
            }
            name
            header
            id
            createdDate
            updatedDate
            hidden
            awyesId
            revision
            description
            keywords
            priority
            typeName: __typename
          }
        }
        minimumOrderAmount
        singleUse
        maxClaims
        claims
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllAwyesOfferCode(query: $query) {
    nextToken
    items {
      ... on AwyesOfferCode {
        id
        code
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllAwyesOfferCode`;

const getAllAwyesOfferCode = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllAwyesOfferCode;