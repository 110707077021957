export const fullProjection = `mutation($input: CategoryPageInput!) {
  updateCategoryPage(input: $input) {
    title
    url
    name
    header
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    description
    keywords
    priority
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: CategoryPageInput!) {
  updateCategoryPage(input: $input) {
    name
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateCategoryPage`;

const updateCategoryPage = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateCategoryPage;