export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllReturnsPolicy(query: $query) {
    nextToken
    items {
      ... on ReturnsPolicy {
        policy
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllReturnsPolicy(query: $query) {
    nextToken
    items {
      ... on ReturnsPolicy {
        id
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllReturnsPolicy`;

const getAllReturnsPolicy = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllReturnsPolicy;