export const fullProjection = `mutation($input: PrivacyPolicyInput!) {
  updatePrivacyPolicy(input: $input) {
    policy
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: PrivacyPolicyInput!) {
  updatePrivacyPolicy(input: $input) {
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updatePrivacyPolicy`;

const updatePrivacyPolicy = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updatePrivacyPolicy;