const fields = [
  {
    "name": "phone",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "whatsapp",
    "required": false,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "email",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "address",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "mapLink",
    "required": false,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "String"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "id",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "ID"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": true,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "hidden",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Boolean"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "revision",
    "required": true,
    "isArray": false,
    "arrayItemsNullable": false,
    "types": [
      "Int"
    ],
    "isEnum": false,
    "isScalar": true,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  },
  {
    "name": "placeholders",
    "required": false,
    "isArray": true,
    "arrayItemsNullable": false,
    "types": [
      "PlaceholderInput"
    ],
    "isEnum": false,
    "isScalar": false,
    "isMultiline": false,
    "isCMSLabel": false,
    "isCMSImage": false,
    "readOnly": false,
    "hidden": false,
    "formatters": []
  }
];

const validators = {
  phone: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [phone]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [phone]';
    if (!/^0[0-9\s]+$/.test(value)) return 'Please enter a valid phone number beginning with 0';
    return true;
  },
  whatsapp: (value) => {
    if (value === undefined || value === null) return true;
    if (value?.constructor !== String) return 'A String is expected for [whatsapp]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [whatsapp]';
    if (!/^0[0-9\s]+$/.test(value)) return 'Please enter a valid phone number beginning with 0';
    return true;
  },
  email: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [email]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [email]';
    if (!/^[^@\s]+@[^@\s]+\.[^@\s]+/i.test(value)) return 'Please enter a valid email address';
    return true;
  },
  address: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [address]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [address]';
    if (value.length < 1) return 'Value too short for [address], min is 1';
    if (value.length > 100) return 'Value too long for [address], max is 100';
    return true;
  },
  mapLink: (value) => {
    if (value === undefined || value === null) return true;
    if (value?.constructor !== String) return 'A String is expected for [mapLink]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [mapLink]';
    if (!/^https?:\/\/.+/.test(value)) return 'Please enter a valid link';
    return true;
  },
  id: (value) => {
    if (value?.constructor !== String) return 'A String is expected for [id]';
    if (!value.trim().length) return 'Please enter a value for [id]';
    if (/[\n\r]/g.test(value)) return 'Multiple lines not allowed for [id]';
    return true;
  },
  hidden: (value) => {
    if (value?.constructor !== Boolean) return 'A Boolean is expected for [hidden]';
    return true;
  },
  revision: (value) => {
    if (!Number.isInteger(value)) return 'An Integer is expected for [revision]';
    return true;
  },
  placeholders: (values) => {
    const checkItem = (value) => {
      if (value?.constructor !== Object) return 'An Object is expected for [placeholders]';
      return true;
    }
    const results = (values ?? []).map(checkItem);
    return results.find((result) => result !== true) ? results : true;
  }
};

const type = { fields, validators, id: 'contact-details', updatable: true, deletable: false };

export default type;