export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllSocialMedia(query: $query) {
    nextToken
    items {
      ... on SocialMedia {
        facebook
        instagram
        ebay
        twitter
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllSocialMedia(query: $query) {
    nextToken
    items {
      ... on SocialMedia {
        id
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllSocialMedia`;

const getAllSocialMedia = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllSocialMedia;