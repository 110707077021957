import React from 'react';
import { useNavigate } from "react-router";
import Button from "../components/Button";

import '../scss/Notice.scss';

export const NoticeDialog = ({ header, messages }) => {
  return (
    <div className="notice__dialog">
      <h1 className="notice__dialog__header">{header}</h1>
      {messages?.map((message, index) => <p key={index} className="notice__dialog__message">{message}</p>)}
    </div>
  )
};

const Notice = ({ header, messages }) => {
  const navigate = useNavigate();

  return (
    <div className="notice">
      <div className="notice__container">
        <NoticeDialog header={header} messages={messages} />
      </div>
      <Button className="notice__continue" onClick={() => navigate('/')}>Continue Shopping</Button>
    </div>
  );
};

export default Notice;