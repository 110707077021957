export const fullProjection = `mutation($input: AwyesNavInput!) {
  updateAwyesNav(input: $input) {
    items {
      id
      typeName
      name
      url
      priority
    }
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `mutation($input: AwyesNavInput!) {
  updateAwyesNav(input: $input) {
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `updateAwyesNav`;

const updateAwyesNav = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default updateAwyesNav;