export const fullProjection = `query($query: AwyesContentSingleQuery!) {
  getQuickLinks(query: $query) {
    name
    links {
      name
      link
      image {
        id
        path
        thumbPath
        name
        mime
        width
        height
        size
        eTag
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
      id
      createdDate
      updatedDate
      hidden
      awyesId
      revision
      typeName: __typename
    }
    id
    createdDate
    updatedDate
    hidden
    awyesId
    revision
    typeName: __typename
  }
}`;

export const shortProjection = `query($query: AwyesContentSingleQuery!) {
  getQuickLinks(query: $query) {
    name
    id
    awyesId
    revision
    typeName: __typename
  }
}`;

export const resolverField = `getQuickLinks`;

const getQuickLinks = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getQuickLinks;