export const fullProjection = `query($query: AwyesContentAllQuery) {
  getAllAwyesNav(query: $query) {
    nextToken
    items {
      ... on AwyesNav {
        items {
          id
          typeName
          name
          url
          priority
        }
        id
        createdDate
        updatedDate
        hidden
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const shortProjection = `query($query: AwyesContentAllQuery) {
  getAllAwyesNav(query: $query) {
    nextToken
    items {
      ... on AwyesNav {
        id
        awyesId
        revision
        typeName: __typename
      }
    }
  }
}`;

export const resolverField = `getAllAwyesNav`;

const getAllAwyesNav = {
  fullProjection,
  shortProjection,
  resolverField,
};

export default getAllAwyesNav;