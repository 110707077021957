import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import useAnalytics from '@awyes/cms/hooks/useAnalytics';
import useCookiePref from '@awyes/cms/hooks/useCookiePref';

import Button from './Button';

import '../scss/CookiePreference.scss';

const CookiePreference = () => {
  const { initialise } = useAnalytics();
  const [preference, { acceptAll, acceptRequired, ACCEPT_ALL }] = useCookiePref();

  useEffect(() => {
    if (preference === ACCEPT_ALL && process.env.NODE_ENV === 'production') {
      initialise();
    }
  }, [preference, initialise, ACCEPT_ALL]);

  return (
    !preference ? createPortal(<div className="cookie-preference">
      <p className="cookie-preference__notice">We use required cookies for functionality and optional ones to help understand how our website is used.</p>
      <div className="cookie-preference__options">
        <Button isMain={true} className="cookie-preference__options__option" onClick={() => acceptAll()}>Accept all</Button>
        <Button className="cookie-preference__options__option" onClick={() => acceptRequired()}>Accept required</Button>
      </div>
    </div>, document.body) : null
  );
};

export default CookiePreference;